import * as React from "react";
import { Layout } from "../components/common/Layout";
import { SectionContainer } from "../components/common/SectionContainer";
import { Typography, Button, Stack, Paper, Grid, Box } from "@mui/material";
import { Link } from "gatsby";
import { SectionTitle } from "../components/common/SectionTitle";
import { BlogPostTags } from "../components/blog/template/BlogPostTags";
import { BlogLayout } from "../components/blog/BlogLayout";
import { useBlogPosts } from "../data/hooks/useBlogPosts";
import { BlogPostPreview } from "../components/blog/template/common/BlogPostPreview";

export default function BlogPostPage() {
  const blogPosts = useBlogPosts();
  return (
    <BlogLayout title="Sample title" noPaper>
      {blogPosts.map((x) => {
        return <BlogPostPreview post={x} key={x.id} />;
      })}
    </BlogLayout>
  );
}
