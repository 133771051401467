import * as React from "react";
import { BlogPostData } from "../../../../data/types/BlogPostData";
import { Paper, Grid, Box, Theme, Typography, Button } from "@mui/material";
import { SectionTitle } from "../../../common/SectionTitle";
import { MarkdownBlock } from "../../../common/MarkdownBlock";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Link } from "gatsby";

export interface IBlogPostPreviewProps {
  post: BlogPostData;
}

export function BlogPostPreview(props: IBlogPostPreviewProps) {
  return (
    <Paper
      sx={{
        padding: "16px 32px",
        paddingTop: "32px",
        marginBottom: "16px",
      }}
      elevation={0}
    >
      <SectionTitle title={props.post.title} />
      <MarkdownBlock data={props.post._rawSummaryText} />
      <Grid container>
        <Grid
          item
          xs={6}
          component={Box}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarTodayIcon
            sx={{ marginRight: (theme: Theme) => theme.spacing(1) }}
            color="primary"
          />
          <Typography variant="subtitle1">{props.post.postDate}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              padding: (theme: Theme) => theme.spacing(1),
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button component={Link} to={`/blog/${props.post.slug.current}`}>
              read more
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
